import type { AnalyticsBrowser } from '@segment/analytics-next'

export function useSegment() {
  const $segment = useNuxtApp().$segment
  const config = useRuntimeConfig().public.segment

  // See: https://segment.com/docs/connections/spec/identify/
  function identify(...args: Parameters<AnalyticsBrowser['identify']>) {
    wrapFn('identify')(...args)
  }

  // See: https://segment.com/docs/connections/spec/page/
  function page(...args: Parameters<AnalyticsBrowser['page']>) {
    wrapFn('page')(...args)
  }

  // See: https://segment.com/docs/connections/spec/track/
  function track(...args: Parameters<AnalyticsBrowser['track']>) {
    wrapFn('track')(...args)
  }

  // See: https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#reset-or-log-out
  function reset(...args: Parameters<AnalyticsBrowser['reset']>) {
    return wrapFn('reset')(...args)
  }

  function shouldTrack() {
    return import.meta.client && config.settings.writeKey
  }

  function wrapFn(fnName: 'identify' | 'page' | 'track' | 'reset') {
    return function (...args: Parameters<AnalyticsBrowser[typeof fnName]>) {
      if (shouldTrack()) {
        // @ts-expect-error We know args should be correct
        return $segment[fnName](...args)
      }
    }
  }

  return {
    $segment,
    identify,
    page,
    track,
    reset,
  }
}
